<template>
  <div>
    <div class="down">
      <ul>
        <li>
          <h3>思维互动课堂移动端</h3>
          <div class="btn-con">APP端</div>
          <p>手机扫码下载</p>
          <div class="down-app">
            <img src="../../assets/app-down.png" />
          </div>
        </li>
        <li>
          <h3>思维互动白板V2.0</h3>
          <a :href="whiteBoardUrl" target="_black" class="btn-con cursor">电脑端下载</a>
          <p>教师授课使用</p>
          <a target="_black" :href="whiteBoardUrl"  class="down-white">
            <div class="down-text">
              <img src="../../assets/down/white-down.png" />
              <div>Windows安装包</div>
            </div>
            <div class="down-white-app">
              <img src="../../assets/down/down-icon.png" />
              <div>立即下载(206MB)</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <understand-more></understand-more>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  toRefs,
  defineComponent,
  readonly,
} from "vue";
import understandMore from "../home/understand-more.vue";
export default defineComponent({
  name: "download",
  components: {
    understandMore,
  },
  setup() {
    const whiteBoardUrl = readonly('https://svcfile.svclass.cn/%E6%80%9D%E7%BB%B4%E4%BA%92%E5%8A%A8%E7%99%BD%E6%9D%BF%E5%AE%89%E8%A3%85%E5%90%91%E5%AF%BC2.0.0.0.7z');
    return {
        whiteBoardUrl
    };
  },
});
</script>

<style lang="scss" scoped>
.down {
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: url("../../assets/down/down-header.png") no-repeat;
  background-size: cover;
  ul {
    width: 1180px;
    display: flex;
    display: flex;
    justify-content: space-around;
    padding-top: 150px;
    text-align: center;
    h3 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      line-height: 50px;
    }
    .btn-con {
      display: block;
      width: 162px;
      height: 54px;
      margin: 30px auto 0;
      background: url("../../assets/down/down-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 22px;
      font-weight: 600;
      color: #2020cf;
      line-height: 54px;
      opacity: 0.8;
      &.cursor {
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 50px;
    }
    .down-app,
    .down-white {
      margin: 20px auto 0;
      width: 280px;
      height: 284px;
      background: #ffffff;
      border-radius: 12px;
      padding: 20px;
      box-sizing: border-box;
    }
    .down-app {
      img {
        width: 240px;
        height: 240px;
      }
    }
    .down-white {
      display: block;
      cursor: pointer;
      .down-text, .down-white-app {
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
        img {
          margin: 40px 0 20px;
          width: 100px;
        }
      }
      .down-white-app {
        color: #2cb0ff;
        display: none;
      }
      &:hover {
        .down-text {
          display: none;
        }
        .down-white-app {
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .down {
    ul {
      width: 960px;
    }
  }
}
</style>