<template>
  <section class="more" id="understand">
    <div>
      <h4>如您想了解更多信息</h4>
      <el-form
        ref="ruleForm"
        class="more-form"
        :model="userForm"
        :rules="rules"
        label-width="130px"
      >
        <div class="information-input">
          <el-form-item class="more-form-item" prop="knowName" label-width="0">
            <el-input
              v-model="userForm.knowName"
              placeholder="请输入您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item class="more-form-item" prop="knowPhone" label-width="0">
            <el-input
              v-model="userForm.knowPhone"
              placeholder="请输入您的手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="more-form-item"
            prop="organizationName"
            label-width="0"
          >
            <el-input
              v-model="userForm.organizationName"
              placeholder="请输入单位名称"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          class="more-form-radio"
          prop="intention"
          label="主要意向："
        >
          <el-radio-group v-model="userForm.intention">
            <el-radio label="1">开通学校账号</el-radio>
            <el-radio label="2">商务合作</el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="more-form-item" label-width="0">
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { reactive, ref, toRefs, defineComponent } from "vue";
import { ElMessage } from "element-plus";
import * as upload from "../../sevices/upload";
export default defineComponent({
  name: "understand-more",
  setup() {
    const _data = reactive({
      userForm: {
        knowName: "", // 姓名
        knowPhone: "", // 售价或
        organizationName: "", // 单位名称
        intention: "1", //意向
      },
      rules: {
        knowName: [
          { required: true, message: "请输入您的姓名", trigger: "change" },
        ],
        knowPhone: [
          { required: true, message: "请输入您的手机号", trigger: "change" },
          { pattern: /^1[34578]\d{9}$/, message: "请输入正确的手机号" },
        ],
        organizationName: [
          { required: true, message: "请输入单位名称", trigger: "change" },
        ],
      },
    });
    const ruleForm = ref(null);
    const onSubmit = () => {
      ruleForm.value.validate(async (valid) => {
        if (valid) {
          await upload.knowAdd(_data.userForm);
          ElMessage({
            message: " 您的信息已提交成功",
            type: "success",
          });
          ruleForm.value.resetFields();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };
    return {
      ...toRefs(_data),
      ruleForm,
      onSubmit,
    };
  },
});
</script>
<style lang="scss" scoped>
.more {
  width: 100%;
  & > div {
    width: 1180px;
    margin: auto;
    box-sizing: border-box;
    padding: 85px 0;
    h4 {
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 50px;
      margin-bottom: 70px;
    }
    .more-form {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      .information-input {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .more-form-item {
        width: 380px;
        height: 45px;
        :deep(.el-form-item__content) {
          line-height: 45px;
          .el-input {
            line-height: 45px;
            .el-input__inner {
              line-height: 45px;
              height: 45px;
              font-size: 18px;
            }
          }
          .el-button {
            // margin-top: 10px;
            width: 100%;
            font-size: 18px;
          }
        }
      }
      :deep(.more-form-radio) {
        .el-radio__inner {
          width: 16px;
          height: 16px;
        }
        .el-form-item__label,
        .el-radio__label {
          font-size: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .more {
    & > div {
      width: 960px;
      padding: 75px 0 85px;
      h4 {
        font-size: 36px;
      }
      .more-form {
        .more-form-item {
          width: 306px;
        }
      }
    }
  }
}
</style>